// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-home-js": () => import("/opt/build/repo/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-scene-js": () => import("/opt/build/repo/src/pages/scene.js" /* webpackChunkName: "component---src-pages-scene-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-colophon-js": () => import("/opt/build/repo/src/pages/colophon.js" /* webpackChunkName: "component---src-pages-colophon-js" */)
}

